import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from 'carbon-components-react';

// import {
//     AppSwitcher32,
//     Notification20,
//     UserAvatar20,
//   } from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from 'react-i18next';

const PageHeader = () => {
  const { t } = useTranslation()

  return (
  <HeaderContainer
    render={({ isSideNavExpanded, onClickSideNavExpand }) => (
      <Header aria-label="LDLN Website">
        <SkipToContent />
        <HeaderMenuButton className="header-menu-button"
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName element={Link} to="/" prefix="LDLN">
            Sailing Team
        </HeaderName>
        <HeaderNavigation aria-label="React App">
          <HeaderMenuItem element={Link} to="/team">{t("members.label")}</HeaderMenuItem>
        </HeaderNavigation>
        <SideNav
          aria-label="Side navigation"
          expanded={isSideNavExpanded}
          isPersistent={false}>
          <SideNavItems>
            <HeaderSideNavItems>
                <HeaderMenuItem element={Link} to="/team">
                    {t("members.label")}
                </HeaderMenuItem>
            </HeaderSideNavItems>
          </SideNavItems>
        </SideNav>
        <HeaderGlobalBar>
            {/* <HeaderGlobalAction aria-label="User Avatar">
                <UserAvatar20 />
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="App Switcher">
                <AppSwitcher32 />
            </HeaderGlobalAction> */}
            <HeaderGlobalAction aria-label="Language">
              <LanguageSelector/>
            </HeaderGlobalAction>
        </HeaderGlobalBar>
      </Header>
    )}
  />
)};

export default PageHeader;
