import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language)
  // console.log("i18n supported languages", i18n.options.supportedLngs)

  const changeLanguage = () => {
    if (language === "es") {
      i18n.changeLanguage('en');
      setLanguage('en')
    } else {
      i18n.changeLanguage('es');
      setLanguage('es')
    }
  };

  return (
    <div className="language-selector" onClick={changeLanguage}>
      {language === 'es' ? (
        <p><strong>ES</strong>/EN</p>
      ) : (
        <p>ES/<strong>EN</strong></p>
      )}
    </div>
  );

};

export default LanguageSelector;