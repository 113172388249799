import App from './App';
import './index.scss';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import configureStore from './store'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAm7iAIxIxUR26SWJMZ5EAqoZpovpu2alk",
  authDomain: "sailingldln.firebaseapp.com",
  projectId: "sailingldln",
  storageBucket: "sailingldln.appspot.com",
  messagingSenderId: "371726046928",
  appId: "1:371726046928:web:9761523fbb003499b130d5",
  measurementId: "G-2VCYCBXF7G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics)

const store = configureStore()

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  )
  
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', renderApp)
  }
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
