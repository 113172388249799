
const ImageText = (props) => {

    if (props.link !== undefined && props.text !== undefined) {
        return (<div className="image-text">{props.image}<a href={props.link} target="_blank" rel="noreferrer">{props.text}</a></div>)
    }
    
    if (props.link === undefined && props.text !== undefined) {
        return (<div className="image-text">{props.image}<p>{props.text}</p></div>)
    }

    if (props.link !== undefined && props.text === undefined) {
        return (<a href={props.link} target="_blank" rel="noreferrer"><div className="image-text">{props.image}</div></a>)
    }

    return (<div className="image-text">{props.image}</div>)
}

export default ImageText;