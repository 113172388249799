import React, { Suspense } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import './App.scss';

/* Pages */
import HomePage from './content/HomePage';
import TeamPage from './content/TeamPage';

/* Components */
import { Content } from 'carbon-components-react';
import PageHeader from './components/PageHeader';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Suspense fallback="loading">
        <PageHeader/>
        <Content>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/team" component={TeamPage} />

            <Route exact path="/:any"><Redirect to="/"/></Route>
          </Switch>
        </Content>
        <Footer/>
      </Suspense>
    </div>
  );

}

export default App;
