import React from 'react';
// import MemberTile from '../../components/MemberTile/MemberTile';
import { useTranslation } from 'react-i18next';

const TeamPage = () => {
  const { t } = useTranslation();

  return (
    <div className="team-page">
      <h1>{t("members.label")}</h1>
      <br/>

      <div className="members-list">
        {/* <MemberTile name="Miembro De Prueba" country="ES" nickname="Prueba23" offshore_record="1" inshore_record="1" roles={['captain']}/>
        <br/>
        <MemberTile name="Miembro" country="ES" nickname="Prueba23" offshore_record="1" inshore_record="1" roles={['captain', 'medallist']}/>
        <br/>
        <MemberTile name="Esto es un miembro de prueba" country="ES" nickname="Prueba23" offshore_record="1" inshore_record="1" roles={['medal']}/>  
        <br/>
        <MemberTile name="Esto es un miembro de prueba" country="ES" nickname="Prueba23" offshore_record="1" inshore_record="1"/>   */}
      </div>
    </div>
  )
};

export default TeamPage;
