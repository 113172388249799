import { LogoDiscord32,  LogoFacebook32, LogoTwitter32, LogoInstagram32, Trophy32 } from "@carbon/icons-react";
import ImageText from "../ImageText";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="footer">
            <div className="links">
                <ImageText image={<LogoDiscord32/>} text={t('joindiscord.label')} link="https://discord.gg/7B5BZ77"/>
                <ImageText image={<Trophy32/>} text="Virtual Regatta Inshore Ranking" link="https://sailranks.com/v/regattas/3636"/>
                <ImageText image={<Trophy32/>} text="Virtual Regatta Offshore Ranking" link="https://sailranks.com/v/regattas/3836"/>
            </div>

            <div className="social">
                <ImageText image={<LogoFacebook32/>} link="https://www.facebook.com/LDLNSailing/"/>
                <ImageText image={<LogoTwitter32/>} link="https://twitter.com/ldlnsailing"/>
                <ImageText image={<LogoInstagram32/>} link="https://www.instagram.com/ldlnsailing/"/>
            </div>
        </div>
    )
}

export default Footer;
