import React from 'react';
import logo from '../../images/logo.jpg'
import gear from '../../images/gear.svg'
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t } = useTranslation();

  return(
    <div>
      <div>
      <h1>{t('title.label')}</h1>
        <br/>
        <img className="App-logo" src={logo} alt="LDLN logo"/>
      </div>
      
      <div>
        <br/><br/>

        <h4>{t('indevelopment.label')}</h4>
        <br/>
        <img className="loading-img" src={gear} alt="Gear"/>
      </div>
    </div>
  )
};

export default HomePage;
